<nz-layout class="app-layout">
  <nz-header>
    <div class="app-header flex justify-between content-center items-center px3">
      <div class="flex">
        <a routerLink="/" class="mr2" nz-tooltip nzTooltipPlacement="bottomLeft" nzTooltipTitle="Tableau de bord">
          <img alt="logo" ngSrc="assets/AmphiboleCompta.png" height="48" width="48" priority>
        </a>

        <div class="cursor-pointer header-text">
          <div nz-popover
               [nzPopoverContent]="appMenu"
               nzPopoverPlacement="bottomLeft"
               [(nzPopoverVisible)]="menuVisible">
            <i nz-icon nzType="fa-duotone:grid" class="mr1"></i>
            Menu
          </div>
        </div>
      </div>

      <div class="header-text">
        {{ pageTitle }}
      </div>

      <nz-flex nzGap="middle" nzAlign="center">
        <div>
          <a [nzDropdownMenu]="menu" class="header-menu" nz-dropdown>
            <ng-container>
              <i nz-icon nzType="fa-regular:user"></i>
              &nbsp;
              {{ fullName }}
            </ng-container>
            <i nz-icon nzType="fa-regular:angle-down"></i>
          </a>

          <nz-dropdown-menu #menu>
            <ul nz-menu nzSelectable>
              <ng-container>
                <li nz-menu-item (click)="myAccount()" nzMatchRouter>
                  <i nz-icon nzType="fa-regular:user"></i>
                  &nbsp;
                  Gérer mon compte ↗
                </li>

                <li nz-menu-item (click)="logout()" nzMatchRouter>
                  <i nz-icon nzType="fa-regular:arrow-right-from-bracket"></i>
                  &nbsp;
                  Se déconnecter
                </li>
              </ng-container>
            </ul>
          </nz-dropdown-menu>
        </div>

        <a nz-button nzSize="large" nzShape="round" (click)="toggleTheme()"
           nz-tooltip nzTooltipTitle="Changer le thème de l'application">
          @if (themeService.currentTheme === ThemeType.default) {
            <i nz-icon nzType="fa-regular:moon"></i>
          } @else {
            <i nz-icon nzType="fa-regular:sun"></i>
          }
        </a>
      </nz-flex>
    </div>
  </nz-header>

  <nz-content class="m2">
    <div class="inner-content p2 h100">
      <router-outlet></router-outlet>
    </div>
  </nz-content>
</nz-layout>
<!--</nz-layout>-->

<ng-template #appMenu>
  <div nz-row [nzGutter]="[16, 16]" [style.width.px]="1000">
    <div nz-col [nzSpan]="24">
      <span nz-typography nzType="secondary" [style.font-size.pt]="14">Les partenaires</span>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/customers" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-duotone:users" class="mr2" [style.color]="'#2980b9'"></i>
            <span class="text">Clients</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/suppliers" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-duotone:person-carry-box" class="mr2" [style.color]="'#2980b9'"></i>
            <span class="text">Fournisseurs</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/employees" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-duotone:user-helmet-safety" class="mr2" [style.color]="'#2980b9'"></i>
            <span class="text">Salariés</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="24">
      <span nz-typography nzType="secondary" [style.font-size.pt]="14">Le catalogue</span>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/supplies" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-duotone:truck" class="mr2" [style.color]="'#f39c12'"></i>
            <span class="text">Matériels et équipements</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/items" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-duotone:warehouse-full" class="mr2" [style.color]="'#f39c12'"></i>
            <span class="text">Éléments</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/tasks" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-duotone:toolbox" class="mr2" [style.color]="'#f39c12'"></i>
            <span class="text">Ouvrages</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="24">
      <span nz-typography nzType="secondary" [style.font-size.pt]="14">Les recettes</span>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/quotes" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-duotone:euro-sign" class="mr2" [style.color]="'#27ae60'"></i>
            <span class="text">Devis</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/orders" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-duotone:receipt" class="mr2" [style.color]="'#27ae60'"></i>
            <span class="text">Commandes</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/invoices" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-duotone:inbox-out" class="mr2" [style.color]="'#27ae60'"></i>
            <span class="text">Factures émises</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="24">
      <span nz-typography nzType="secondary" [style.font-size.pt]="14">Les charges</span>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/bills" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-duotone:inbox-in" class="mr2" [style.color]="'#c0392b'"></i>
            <span class="text">Factures reçues</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/receipts" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-duotone:barcode-read" class="mr2" [style.color]="'#c0392b'"></i>
            <span class="text">Tickets et reçus</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/unpaid" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-duotone:file-xmark" class="mr2" [style.color]="'#c0392b'"></i>
            <span class="text">Factures impayées</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/purchase-orders" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-duotone:cart-shopping" class="mr2" [style.color]="'#c0392b'"></i>
            <span class="text">Bons de commandes</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="24">
      <span nz-typography nzType="secondary" [style.font-size.pt]="14">Les outils</span>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/mailing" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-duotone:paper-plane" class="mr2" [style.color]="'#16a085'"></i>
            <span class="text">Mailing</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/worksites" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-duotone:person-digging" class="mr2" [style.color]="'#16a085'"></i>
            <span class="text">Chantiers</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/accounting" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-duotone:calculator" class="mr2" [style.color]="'#16a085'"></i>
            <span class="text">Comptabilité</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/notes" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-duotone:notes" class="mr2" [style.color]="'#16a085'"></i>
            <span class="text">Notes</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/archives" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-duotone:box-archive" class="mr2" [style.color]="'#16a085'"></i>
            <span class="text">Archives</span>
          </div>
        </nz-card>
      </a>
    </div>

    <div nz-col [nzSpan]="24">
      <span nz-typography nzType="secondary" [style.font-size.pt]="14">Paramètres</span>
    </div>

    <div nz-col [nzSpan]="8">
      <a routerLink="/settings" (click)="closeMenu()">
        <nz-card [nzHoverable]="true" class="h100" nzSize="small">
          <div class="w100 h100 flex items-center">
            <i nz-icon nzType="fa-duotone:gear" class="mr2" [style.color]="'#34495e'"></i>
            <span class="text">Paramètres</span>
          </div>
        </nz-card>
      </a>
    </div>
  </div>
</ng-template>
